import { React, useEffect, useState } from "react";
// import useFetch from "hooks/useFetch";

import axios from "axios";
import TopNav from "../TopNav";
import SideNav from "../SideNav";

const Permission = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <body>
        <div class="main-wrapper">
          <TopNav />

          <SideNav />

          <div class="page-wrapper">
<div class="content">
<div class="page-header">
<div class="add-item d-flex">
<div class="page-title">
<h4>Permission</h4>
<h6>Manage your permissions</h6>
</div>
</div>
<ul class="table-top-head">
<li>
<a data-bs-toggle="tooltip" data-bs-placement="top" title="Pdf"><img src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/pdf.svg" alt="img" /></a>
</li>
<li>
<a data-bs-toggle="tooltip" data-bs-placement="top" title="Excel"><img src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/excel.svg" alt="img" /></a>
</li>
<li>
<a data-bs-toggle="tooltip" data-bs-placement="top" title="Print"><i data-feather="printer" class="feather-rotate-ccw"></i></a>
</li>
<li>
<a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i></a>
</li>
<li>
<a data-bs-toggle="tooltip" data-bs-placement="top" title="Collapse" id="collapse-header"><i data-feather="chevron-up" class="feather-chevron-up"></i></a>
</li>
</ul>
</div>

<div class="card table-list-card">
<div class="card-body">
<div class="table-top">
<div class="search-set">
<div class="search-input">
<a href class="btn btn-searchset"><i data-feather="search" class="feather-search"></i></a>
</div>
</div>
<div class="search-path">
<a class="btn btn-filter" id="filter_search">
<i data-feather="filter" class="filter-icon"></i>
<span><img src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/closes.svg" alt="img" /></span>
</a>
</div>
<div class="form-sort">
<i data-feather="sliders" class="info-img"></i>
<select class="select">
<option>Sort by Date</option>
<option>Newest</option>
<option>Oldest</option>
</select>
</div>
</div>

<div class="card" id="filter_inputs">
<div class="card-body pb-0">
<div class="row">
<div class="col-lg-3 col-sm-6 col-12">
<div class="input-blocks">
<i data-feather="zap" class="info-img"></i>
<select class="select">
<option>Choose Role</option>
<option>Admin</option>
<option>Shop Owner</option>
</select>
</div>
</div>
<div class="col-lg-3 col-sm-6 col-12">
<div class="input-blocks">
<i data-feather="calendar" class="info-img"></i>
<div class="input-groupicon">
<input type="text" class="datetimepicker" placeholder="Choose Date" />
</div>
</div>
</div>
<div class="col-lg-3 col-sm-6 col-12 ms-auto">
<div class="input-blocks">
<a class="btn btn-filters ms-auto"> <i data-feather="search" class="feather-search"></i> Search </a>
</div>
</div>
</div>
</div>
</div>

<div class="table-responsive">
<table class="table  datanew">
<thead>
<tr>
<th class="no-sort">
<label class="checkboxs">
<input type="checkbox" id="select-all" />
<span class="checkmarks"></span>
</label>
</th>
<th>Modules</th>
<th>Create</th>
<th>Edit</th>
<th>Delete</th>
<th>View</th>
<th class="no-sort">Allow all</th>
</tr>
</thead>
<tbody>
<tr>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>Inventory</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
</tr>
<tr>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>Expense</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
</tr>
<tr>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>Product</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
</tr>
<tr>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>Settings</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
</tr>
<tr>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>Category</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
<td>
<label class="checkboxs">
<input type="checkbox" />
<span class="checkmarks"></span>
</label>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>

</div>
</div>
        </div>
      </body>
    </div>
  );
};

export default Permission;
